import { useTranslation } from "react-i18next";
import { phone, address, email, wechat, wechatIcon } from "../assets";
import "./Contact.css";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-container">
      <h3 className="contact-title">{t("contact.title")}</h3>
      <div className="contact-card">
        <img src={address} className="icon" alt="address" />
        <section className="info">
          <h3>{t("contact.address")}:</h3>
          <p>135 Dawn Dr</p>
          <p>Shirley, NY 11967</p>
        </section>
        <img src={phone} className="icon" alt="phone" />
        <section className="info">
          <h3>{t("contact.phone")}:</h3>
          <p>+1 (910) 578-9898</p>
        </section>
      </div>
      <div className="contact-card">
        <img src={email} className="icon" alt="email" />
        <section className="info">
          <h3>{t("contact.email")}:</h3>
          <p>contact@baymaxaviation.com</p>
        </section>
        <img src={wechatIcon} className="icon" alt="wechat-icon" />
        <section className="info">
          <h3>{t("contact.wechat")}:</h3>
          <p>BaymaxAviationNYC</p>
        </section>
      </div>
      <div className="contact-card">
        <h3 className="code">{t("contact.wechatCode")}</h3>
        <img src={wechat} className="wechat" alt="wechat" />
      </div>
    </div>
  );
};

export default Contact;
