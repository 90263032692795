import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { background } from "../assets";
import "./Navbar.css";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const [active, setActive] = useState(false);
  const setActiveHandler = () => {
    setActive((prev) => setActive(!prev));
  };
  const { pathname } = useLocation();
  const getTitle = (pathname) => {
    let title;
    switch (pathname.substring(1)) {
      case "tours":
        title = t("title.tours");
        break;
      case "training":
        title = t("title.training");
        break;
      case "book":
        title = t("title.book");
        break;
      case "profile":
        title = t("title.profile");
        break;
      case "faq":
        title = t("title.faq");
        break;
      case "contact":
        title = t("title.contact");
        break;
      case "":
        title = "";
        break;
      default:
        title = "Baymax";
    }
    return title;
  };

  return (
    <>
      <div
        className={pathname !== "/" ? "background-img" : ""}
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <h3 className="background-title">{getTitle(pathname)}</h3>
      <div className="background-color" />
      <header>
        <NavLink to="/" className="brand">
          Baymax Aviation
        </NavLink>
        <ul className={`menu ${active ? "active" : ""}`}>
          <li onClick={setActiveHandler}>
            <NavLink
              to="/tours"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {t("title.tours")}
            </NavLink>
          </li>
          <li onClick={setActiveHandler}>
            <NavLink
              to="/training"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {t("title.training")}
            </NavLink>
          </li>
          <li onClick={setActiveHandler}>
            <NavLink
              to="/book"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {t("title.book")}
            </NavLink>
          </li>
          <li onClick={() => loginWithRedirect()}>
            <NavLink>{t("title.profile")}</NavLink>
          </li>
          <li onClick={setActiveHandler}>
            <NavLink
              to="/faq"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {t("title.faq")}
            </NavLink>
          </li>
          <li onClick={setActiveHandler}>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {t("title.contact")}
            </NavLink>
          </li>
          <li>
            <button
              className="lang-select"
              onClick={() =>
                i18n.changeLanguage(
                  i18next.resolvedLanguage === "en" ? "zh" : "en"
                )
              }
            >
              {i18next.resolvedLanguage === "en" ? "🇨🇳中文" : "🇺🇸English"}
            </button>
          </li>
        </ul>
        <div
          className={`hamburger ${active ? "active" : ""}`}
          onClick={setActiveHandler}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </header>
    </>
  );
};

export default Navbar;
