import "./Book.css";

const Book = () => {
  return (
    <div className="calendly-widget">
      <iframe
        src={`https://calendly.com/baymaxaviation/discovery?hide_event_type_details=1&hide_gdpr_banner=1&embed_type=Inline&embed_domain=1`}
        style={{ border: "none", height:"100%", width: "100%"}}
        title="Calendly Scheduling Page"
      ></iframe>
    </div>
  );
};

export default Book;
